<template>
  <div>
    <commonTable
      :tableData="goodData.list"
      :loading="loading"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
      :currentPage="currentPage"
      :total="goodData.total"
    >
      <template v-slot:table>
        <el-table-column align="center" width="70">
          <template slot-scope="scope">
            <el-radio v-model="currentRowId" :label="scope.row.goodsId" @change="handleSelectionChange(scope.row)" >
              {{ ' ' }}
            </el-radio>
          </template>
        </el-table-column>
        <el-table-column
          prop="goodsId"
          align="center"
          label="产品ID"
          show-overflow-tooltip
        />
        <el-table-column prop="goodsName" align="center" label="产品" />
        <el-table-column align="center" prop="typeName" label="分类" />
        <el-table-column prop="price" align="center" label="价格">
           <template slot-scope="scope">
             {{scope.row.price | formatAmount}}
           </template>
        </el-table-column>
        <el-table-column prop="stockNum" align="center" label="库存" />
        <el-table-column prop="dealNum" width="100" align="center" label="总销量" />
        <el-table-column align="center" label="发布时间">
          <template slot-scope="scope">
            {{ setGetDataTimeSec(scope.row.createTime) }}
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import { getGoodsInfoPC } from "@/api/marketingActivities";
import commonTable from "@/components/common/commonTable";
import { getDataTimeSec } from "@/utils";
import {get} from '@/utils/request'
import good from "@/mixins/good";
export default {
  mixins: [good],
  data() {
    return {
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      currentRowId:'',
      multipleSelection: {},
      goodData: {},
      loading:false
    };
  },
  filters:{

  },
  components: {
    commonTable,
  },
  computed: {
    setGetDataTimeSec() {
      return (val) => getDataTimeSec(val);
    },
  },
  created() {
    this.getGoodsInfoPC();
  },
  methods: {
    async getGoodsInfoPC() {
      let data = {
        pageNum: this.currentPage, //当前页
          pageSize: this.pageSize, //显示条数
      }
      get("/goods/goodList",data).then(res=>{
          this.goodData=res
      })
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getGoodsInfoPC()
    },
    handleSelectionChange(val) {
      this.currentRowId = val.goodsId;
      this.multipleSelection = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getGoodsInfoPC();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
